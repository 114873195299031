@use "theme";

$primary-color: theme.$primary-color;
$accent-color: theme.$accent-color;
$warn-color: theme.$warn-color;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.fullscreen-dialog .mat-mdc-dialog-container {
  padding: 0;
  max-width: "100vw";
  max-height: "100vh";
  height: "100%";
  width: "100%";
}

.no-data-row {
  text-align: center;
  padding: 1rem 0;
  color: #777777;
}

.ns {
  user-select: none;
}

.photo-btn .mat-badge-content {
  right: -0.2rem !important;
  top: -0.2rem !important;
}

.side-nav-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}

.side-nav-expansion-panel-header {
  padding-left: 0 !important;
  height: 56px;
}

.side-nav-expansion-panel-header-item::before {
  background-color: transparent !important;
}
