@use "sass:map";
@use "@angular/material" as mat;
@import "./custom-palettes.scss";

@include mat.core();

/* ======== Angular material custom themes ======== */
$dynamic-theme-primary: mat.define-palette($dynamic-theme-palette);
$dynamic-theme-accent: mat.define-palette(
  $dynamic-theme-palette,
  A200,
  A100,
  A400
);
$dynamic-theme-warn: mat.define-palette($dynamic-theme-warn-palette);

$bm-theme: mat.define-light-theme(
  (
    color: (
      primary: $dynamic-theme-primary,
      accent: $dynamic-theme-accent,
      warn: $dynamic-theme-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

$primary-color: mat.get-color-from-palette($dynamic-theme-primary, 500);
$accent-color: mat.get-color-from-palette($dynamic-theme-accent, A200);
$warn-color: mat.get-color-from-palette($dynamic-theme-warn, default);

@include mat.core-theme($bm-theme);

// We are using the CSS of all material components, if we later want to selectively
// import CSS for only component we are using, use e.g.: @include mat.button-theme($bm-theme);
@include mat.all-component-themes($bm-theme);
@include mat.all-component-bases($bm-theme);
@include mat.all-component-typographies($bm-theme);
@include mat.all-component-densities($bm-theme);
